import styled from "styled-components";

export const ContainerTextConcluded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Libre Franklin', sans-serif;
  @media (min-width: 800px) {
    align-items: flex-start;
    justify-content: space-evenly;
  }
  span{
    font-weight: 500;
    font-size: 4vw;
    text-align: center;
    width: 72.533vw;
    @media (min-width: 800px) {
    text-align: left;
    font-size: 1.125vw;
    width: 31.687vw;
   }
  }
  
`;

export const IconConcluded = styled.img`
  width: 8.266vw;
  height: 8.266vw;
  margin-bottom: 3.226vw;
  @media (min-width: 800px) {
    width: 1.533vw;
    height: 1.533vw;
    margin-bottom: 0vw;
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    width: 980px;
  }
`;

export const ContainerTitleAndIcon = styled.div`
  display: flex;
  width: 73.33vw;
  flex-direction: column;
  align-items: center;
  font-family: 'Libre Franklin', sans-serif;
  margin: 0 0 3.2vw 0;
  @media (min-width: 800px) {
    align-items: center;
    flex-direction: row;
    width: 26.25vw;
    margin: 0.895vw 0 0.562vw 0;
  }
  p {
    font-weight: 700;
    font-size: 5.333vw;
    @media (min-width: 800px) {
    text-align: left;
    font-size: 1.75vw;
    margin-left: 0.487vw;
   }
  }
`