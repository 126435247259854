import axios from "axios";

import Swal from "sweetalert2";

export default async function createPagarmeCardToken(paymentCardData) {
  try {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_PAGARME_BASE_URL}/tokens`,
      params: { appId: process.env.REACT_APP_PAGARME_PUBLIC_KEY_LIVE },
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      data: {
        type: "card",
        card: {
          number: paymentCardData.cardNumber,
          holder_name: paymentCardData.cardHolderName
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          exp_month: Number(paymentCardData.expMonth),
          exp_year: Number(paymentCardData.expYear),
          cvv: paymentCardData.cardCvv,
        },
      },
    };

    const { data } = await axios.request(options);

    return data.id;
  } catch (e) {
    console.log("ERRRORRR", e);
    void Swal.fire({
      icon: "error",
      title: "Ocorreu um erro",
      text: "Por favor, verifique os dados do cartão e tente novamente!.",
      iconColor: "black",
      confirmButtonColor: "black",
    });
    return "Error";
  }
}
