import styled from "styled-components";

export const ButtonPayment = styled.button`
  width: 89.333vw;
  height: 13.333vw;
  border-style: solid;
  border-radius: 2.666vw;
  border-color: #C7C7C7;
  border-width: 2px;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 4.266vw;
  background-color: transparent;
  margin: 0 0 1.5vh 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;
  @media (min-width: 800px) {
    width: 26.8vw;
    height: 3vw;
    font-size: 1vw;
    border-radius: 0.625vw;
  }
`;

export const IconPaymentMethod = styled.img`
  margin: 0 2vw 0 4vw;
  width: ${(props) => props.widthIcon};
  height: ${(props) => props.heightIcon};
  @media (min-width: 800px) {
    margin: 0 7.9px 0 14.9px;
    width: ${(props) => props.widthIconMedia};
    height: ${(props) => props.heightIconMedia};
  }
`;
