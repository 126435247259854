/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Context from "../../Context/Context";

import { ContainerPurchaseResume } from "../../components/ContainerPurchaseResume/style";
import { GeneralContainerPages } from "../../components/GeneralContainerPages/style";
import { ContainerTextPurchase } from "../../components/ContainerTextPurchase/style";
import { ContainerCourseImage } from "../../components/ContainerCourseImage/style";
import {
  ContainerSecondStrip,
  IconsHeader,
} from "../../components/Header/style";
import ContainerInformations from "../../components/ContainerInformations/style";
import ResolvaIconSVG from "../../assets/images/header-icons/resolva-icon.svg";
import { GeneralContainer } from "../../components/GeneralContainer/style";
import ContainerIcons from "../../components/ContainerIcons/style";
import MethodCreditCard from "../../components/MethodCreditCard";
import { IconGapLine } from "../../components/IconGapLine/style";
import IconsPurchase from "../../components/IconPurchase/style";
import { ResumeText } from "../ChekoutResolva/style";
import MethodPix from "../../components/MethodPix";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import secondIcon from "../../assets/images/second-icon.svg";
import firstIcon from "../../assets/images/first-icon.svg";
import thirdIcon from "../../assets/images/third-icon.svg";

export default function PaymentType() {
  const { courseData } = useContext(Context);

  const { courseId, courseTitle, paymentMethod } = useParams();

  const pixPrice =
    Number(courseData.currentValue) - Number(courseData.currentValue) * 0.1;

  const navigate = useNavigate();

  useEffect(() => {
    if (courseData.title === "") {
      navigate(`/checkout-resolva/${courseId}/${courseTitle}`);
    }
  }, []);

  return (
    <GeneralContainerPages>
      <Header display="none" />
      <GeneralContainer>
        <ContainerSecondStrip
          display="flex"
          displayMobile="none"
          alt="logo desktop"
        >
          <IconsHeader
            src={ResolvaIconSVG}
            mediaWidth="3.2vw"
            mediaHeight="4.5vw"
          ></IconsHeader>
          CHECKOUT DO RESOLVA
        </ContainerSecondStrip>
        <ContainerIcons displayMobile="none" display="flex">
          <IconsPurchase src={firstIcon} mediaWidth="7.8vw" />
          <IconGapLine />
          <IconsPurchase src={secondIcon} mediaWidth="9.2vw" />
          <IconGapLine />
          <IconsPurchase src={thirdIcon} mediaWidth="9.2vw" />
        </ContainerIcons>
        <ContainerInformations>
          <ContainerPurchaseResume>
            <ResumeText>Resumo da Compra</ResumeText>
            <ContainerCourseImage backImage={courseData.courseImage} />
            <ContainerTextPurchase>
              <h3>{courseData.title}</h3>
            </ContainerTextPurchase>
            <ContainerTextPurchase>
              <h3>Online</h3>
            </ContainerTextPurchase>
            <ContainerTextPurchase
              height="10vh"
              flexDirection="row"
              top="4vh"
              height2="6vh"
              justify="space-between"
              align="center"
              mediaHeight="70px"
              alignDesktop="center"
            >
              <p>Total:</p>
              {paymentMethod === "credit-card"
                ? Number(courseData.currentValue)?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                : pixPrice?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
            </ContainerTextPurchase>
          </ContainerPurchaseResume>
          <ContainerIcons display="none">
            <IconsPurchase src={firstIcon} />
            <IconGapLine />
            <IconsPurchase src={secondIcon} />
            <IconGapLine />
            <IconsPurchase src={thirdIcon} />
          </ContainerIcons>
          {paymentMethod === "credit-card" ? (
            <MethodCreditCard />
          ) : (
            <MethodPix />
          )}
        </ContainerInformations>
      </GeneralContainer>
      <Footer />
    </GeneralContainerPages>
  );
}
