const thirtyDays = [4, 6, 9, 11];

export function checkCpf(inputCPF) {
  let soma = 0;
  let resto;
  if (inputCPF == '00000000000') return false;
  for (let i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if ((resto == 10) || (resto == 11)) resto = 0;
  if (resto != parseInt(inputCPF.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if ((resto == 10) || (resto == 11)) resto = 0;
  if (resto != parseInt(inputCPF.substring(10, 11))) return false;
  return true;
}

export function checkName(name) {
  const regexName = /\w+\s+\w/
  const nameIsValid = regexName.test(name)
  return nameIsValid
}

export const checkBirthDate = (birthday) => {
  if (birthday.length !== 10) return false
  const [ day, month, year ] = birthday.split('/')
  const currentTime = new Date()
  const currentYear = currentTime.getFullYear()
  if (thirtyDays.includes(Number(month)) && Number(day) > 30 ) return false
  if(Number(day) > 31 || Number(day) < 1) return false
  if(Number(day) > 29 && Number(month) === 2) return false
  if (Number(month) > 12 || Number(month) < 1) return false
  if(Number(year) > Number(currentYear - 5)) return false
  return true
}
