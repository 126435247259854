import styled from 'styled-components';

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || 'center'};
  @media (min-width: 800px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    /* padding-left: 15px; */
    padding-bottom: 0.8vh;
    width: 100vw;
  }
`;