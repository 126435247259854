import styled from "styled-components";

export const ContainerFirstStripHeader = styled.div`
  background-color: #000000;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerTextFirstStrip = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  font-family: 'Outfit', sans-serif;
  font-size: 3.2vw;
  color: #ffffff;
  font-weight: lighter;
  text-indent: 2vw;
  @media (min-width: 800px) {
    font-size: 0.625vw;
  }
`;

export const IconsHeader = styled.img`
  @media (min-width: 800px) {
    width: ${(props) => props.mediaWidth};
    height: ${(props) => props.mediaHeight};
  }
`;

export const ContainerSecondStrip = styled.div`
  display: ${(props) => props.displayMobile || 'flex'};
  width: 87.3vw;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'center'};
  font-family: 'Outfit', sans-serif;
  font-size: 5.333vw;
  font-weight: 300;
  text-indent: 3vw;
  padding: 3vh 0 5vh 0;
 
  @media (min-width: 800px) {
    font-size: 1.6vw;
    display: ${(props) => props.display};
    width: 26.8vw;
    justify-content: flex-start;
    margin-left: ${(props) => props.marginLeft || '20.7vw'};
    text-indent: 23.7px;
    padding: 1.8vw 0 2.5vw 0;
    font-size: 1.25vw;
  }
`;