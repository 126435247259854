import styled from 'styled-components';

export const ContainerCourseImage = styled.div`
  display: flex;
  background-image: url(${(props) => props.backImage});
  background-size: cover;
  width: 90vw;
  height: 45.2vw;
  @media (min-width: 800px) {
    width: 21.4vw;
    height: 10.687vw;
    background-size: cover;
    margin-bottom: 10px;
  }
`;