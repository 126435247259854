import styled from 'styled-components';

export const ErrorMsg = styled.p`
  color: red;
  margin-bottom: -1vh;
  margin-top: 1vh;
  visibility: ${(props) => props.visibility};
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-size: 3.468vw;
  text-align: center;

  @media (min-width: 800px) {
    display: ${(props) => props.display};
    font-size: 0.813vw;
  }
`