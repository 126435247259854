import styled from 'styled-components'

export const ButtonExpandPurchaseResume = styled.button`
  display: flex;
  width: 89.333vw;
  font-family: 'Libre Franklin', sans-serif;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  text-indent: 2vw;
  font-size: 3.733vw;
  padding-bottom: 2.1vh;
  @media (min-width: 800px) {
    display: none;
    font-size: 0.875vw;
  }
`;

export const IconsPurchase = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: ${(props) => props.leftMobile};
  @media (min-width: 800px) {
    width: ${(props) => props.mediaWidth};
    height: ${(props) => props.mediaHeight};
    margin-left: ${(props) => props.left};
  }
`;

export const ContainerInsideButton = styled.div`
  display: flex;
  align-items: center;
`;

export const IconTrashContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20vw;
  @media (min-width: 800px) {
    width: 500px;
    display: flex;
  }
`;

export const ContainerTotalPrice = styled.div`
  display: flex;
  font-size: 3.733vw;
  font-weight: 500;
  font-family: 'Libre Franklin', sans-serif;
  @media (min-width: 800px) {
    font-size: 0.875vw;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  width: 89.333vw;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 0 2vh 0;
  @media (min-width: 800px) {
    width: 26.8vw;
  }
`;

export const ContainerSelectCardOrModify = styled.div`
  display: flex;
  width: 89.333vw;
  font-family: 'Libre Franklin', sans-serif;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  border-radius: 2.666vw;
  text-indent: 2vw;
  font-size: 3.733vw;
  height: 13.333vw;
  margin-bottom: 6vw;
  @media (min-width: 800px) {
    width: 26.8vw;
    height: 3vw;
    margin-bottom: 1vw;
    height: 3.1vw;
    font-size: 0.875vw;
    border-radius: 0.625vw;
  }
`;

export const ContainerEachItemSelectCard = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3.468vw;
  color: #ffffff;
  margin-right: 3vw;
  cursor: pointer;
  @media (min-width: 800px) {
      font-size: 0.813vw;
    }

  p {
    font-family: 'Libre Franklin', sans-serif;
    font-size: 4.266vw;
    color: #ffffff;
    text-indent: 2vw;
    flex: none;
    cursor: default;
    @media (min-width: 800px) {
      text-indent: 10px;
      font-size: 1vw;
    }
  }
`;

export const ContainerTextsBankSlip = styled.div`
  display: flex;
  width: 89.333vw;
  flex-direction: column;
  font-size: 5.333vw;
  font-weight: 700;
  font-family: 'Libre Franklin', sans-serif;
  align-items: flex-start;
  padding-top: 2vh;
  @media (min-width: 800px) {
    width: 28.1vw;
    font-size: 1.562vw;
  }
  
  p {
  margin-top: 1.5vh;
  font-size: 3.733vw;
  font-weight: 500;
  font-family: 'Libre Franklin', sans-serif;
  @media (min-width: 800px) {
    width: 400px;
    font-size: 0.875vw;
  }
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    width: 26.8vw;
    order: 1;
  }
`;