import styled from 'styled-components'

export const ButtonExpandPurchaseResume = styled.button`
  display: flex;
  width: 89.333vw;
  font-family: 'Libre Franklin', sans-serif;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  text-indent: 2vw;
  font-size: 3.733vw;
  padding-bottom: 2.1vh;
  @media (min-width: 800px) {
    display: none;
    font-size: 0.875vw;
  }
`;

export const ContainerInsideButton = styled.div`
  display: flex;
  align-items: center;
`;

export const IconTrashContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20vw;
  @media (min-width: 800px) {
    width: 500px;
    display: flex;
  }
`;


export const ContainerTotalPrice = styled.div`
  display: flex;
  font-size: 3.733vw;
  font-weight: 500;
  font-family: 'Libre Franklin', sans-serif;
  @media (min-width: 800px) {
      font-size: 0.875vw;
  }
`;

export const ContainerSelectCardOrModify = styled.div`
  display: flex;
  width: 89.333vw;
  font-family: 'Libre Franklin', sans-serif;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  border-radius: 2.666vw;
  text-indent: 2vw;
  font-size: 3.733vw;
  height: 6vh;
  @media (min-width: 800px) {
      font-size: 0.875vw;
      border-radius: 0.625vw;
  }
`;

export const ContainerEachItemSelectCard = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3.468vw;
  color: #ffffff;
  margin-right: 4vw;
  p {
    font-family: 'Libre Franklin', sans-serif;
    font-size: 4.266vw;
    color: #ffffff;
    text-indent: 2vw;
    @media (min-width: 800px) {
      text-indent: 10px;
      font-size: 1vw;
    }
  }
  @media (min-width: 800px) {
      font-size: 0.813vw;
    }
`;

export const DropdownInstallments = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3.468vw;
  padding: 2vh 0 1.5vh 0 ;
  font-weight: 500;
  @media (min-width: 800px) {
    padding: 10px 0 5px 0;
    width: 26.8vw;
    font-size: 0.813vw;
  }
`;

export const SelectInstallments = styled.select`
  width: 89.333vw;
  height: 13.333vw;
  border-radius: 2.666vw;
  border: 1px solid #cccccc;
  text-indent: 3vw;
  font-size: 4.266vw;
  font-family: 'Libre Franklin', sans-serif;
  color: #000000;
  @media (min-width: 800px) {
    width: 26.8vw;
    height: 3vw;
    text-indent: 14px;
    border-radius: 0.625vw;
    font-size: 1vw;
  }
`;

export const ContainerValidity = styled.div`
  width: 89.333vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 800px) {
    width: 26.8vw;
  }
`;

