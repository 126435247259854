import React from "react";
import { ContainerFooterMobile, ContainerTextAndIconFooter, IconsFooter } from "./style";
import WhiteWhatsappIconSVG from '../../assets/images/checkout-icons/white-whatsapp-icon.svg';
import WhiteMailIconSVG from '../../assets/images/checkout-icons/white-mail-icon.svg';
import LineIconFooterSVG from '../../assets/images/checkout-icons/line-icon-footer.svg';

export default function FooterMobile({ bottomPosition, position, bottomMobile, positionMobile }) {
  return (
    <ContainerFooterMobile bottomPosition={bottomPosition} position={position} bottomMobile={bottomMobile} positionMobile={positionMobile}>
      <ContainerTextAndIconFooter
        bottom='2vw'
        marginContainer='0 0 0 4vw'
        >
          Resolva Meu Look © 2022 - Todos os direitos reservados
      </ContainerTextAndIconFooter>
      <ContainerTextAndIconFooter
        indent='1.333vw'
        marginContainer='0 8vw 0 0'
      >
        <IconsFooter 
          src={WhiteWhatsappIconSVG}
          widthIcon='3.536vw'
          heightIcon='3.536vw'
          widthMedia='0.828vw'
          heightMedia='0.828vw'
        />
          (11) 93400-9574
        <IconsFooter
          src={LineIconFooterSVG}
          margin='0 1.256vw 0 1.256vw'
          marginMobile='0 2.666vw 0 2.666vw'
          widthIcon='3.536vw'
          heightIcon='3.536vw'
          widthMedia='0.828vw'
          heightMedia='0.828vw'
        />
        <IconsFooter
          src={WhiteMailIconSVG}
          widthIcon='3.733vw'
          heightIcon='2.8vw'
          widthMedia='0.875vw'
          heightMedia='0.656vw'
        />
          atendimento@resolvameulook.com
      </ContainerTextAndIconFooter>
    </ContainerFooterMobile>
  )
}