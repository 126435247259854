import styled from "styled-components";

const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default ContainerInformations;