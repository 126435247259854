import styled from "styled-components";

export const ContainerFooterMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 21vw;
  background-color: #000000;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: ${(props) => props.positionMobile};
  bottom: ${(props) => props.bottomMobile};
  @media (min-width: 800px) {
    position: ${(props) => props.position};
    bottom: ${(props) => props.bottomPosition};
    flex-direction: row;
    height: 3.6vw;
    gap: 16.5vw;
  }
`;

export const ContainerTextAndIconFooter = styled.div`
  display: flex;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 2.666vw;
  font-weight: 500;
  background-color: transparent;
  color: #ffffff;
  text-indent: ${(props) => props.indent};
  margin-bottom: ${(props) => props.bottom};
  align-items: center;
  text-align: center;
  @media (min-width: 800px) {
    flex-direction: row;
    margin: ${(props) => props.marginContainer};
    text-indent: 0.3vw;
    font-size: 0.625vw;
  }
`;

export const IconsFooter = styled.img`
  background-color: transparent;
  margin: ${(props) => props.marginMobile};
  width: ${(props) => props.widthIcon};
  height: ${(props) => props.heightIcon};
  @media (min-width: 800px) {
  margin: ${(props) => props.margin};
  width: ${(props) => props.widthMedia};
  height: ${(props) => props.heightMedia};
  }

`;