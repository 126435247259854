import styled from "styled-components";

export const ContainerPrice = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  font-family: 'Libre Franklin', sans-serif;
  padding-bottom: 5.333vw;
  margin-left: 9.6vw;
  @media (min-width: 800px) {
    padding-bottom: 1.25vw;
    margin-left: 3vw;
  }
  h2 {
    font-weight: 400;
    font-size: 3.733vw;
    text-decoration: line-through;
    text-align: right;
    @media (min-width: 800px) {
    font-size: 0.875vw;
  }
  }
  span {
    font-weight: 700;
    font-size: 5.866vw;
    @media (min-width: 800px) {
    font-size: 1.375vw;
  }
  }
`