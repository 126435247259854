import React from 'react'
import { ContainerLabelAndInputText, InputLabel, InputTextContainer } from '../InputText/style'
import { StateSelect } from './style'

const statesArray = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']

function Select({
    value, placeholder, text, width, mediaHeight,
    mediaWidth, disable, name, handleInputChange
  }) {
  return (
    <ContainerLabelAndInputText width={mediaWidth}>
      <InputTextContainer>
        <InputLabel>{ text }</InputLabel>
        <StateSelect
          name={name}
          width={width}
          mediaWidth={mediaWidth}
          mediaHeight={mediaHeight}
          disabled={disable}
          onChange={handleInputChange}
          value={value}
          placeholder={placeholder}
          required
        >
          <option
            value="" 
            disabled hidden
            styled={{ fontFamily: 'Libre Franklin sans-serif', color: "#CCCCCC" }}
          >
              Ex. SP
          </option>
          {
            statesArray.map((state, index) => (
              <option key={index} value={state} >
                {state}
              </option>
            ))
          }
        </StateSelect>
      </InputTextContainer>
    </ContainerLabelAndInputText>
  )
}

export default Select