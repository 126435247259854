import styled from "styled-components";

export const InputLabel = styled.label`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-size: 3.468vw;
  padding-bottom: 0.8vh;
  background-color: ${(props) => props.bg};
  padding-left: 3.733vw;
  @media (min-width: 800px) {
    font-size: 0.813vw;
    padding-left: 0.875vw;
  }
`;

export const ContainerLabelAndInputText = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    width: ${(props) => props.width || '26.8vw'};
  }
`;

export const InputText = styled.input`
  width: ${(props) => props.width || '89.333vw'};
  height: 13.333vw;
  border-radius: ${(props) => props.radius || '2.666vw'};
  border: solid 1px;
  border-color: ${(props) => props.borderColor || '#C7C7C7'};
  margin-bottom: ${(props) => props.marginBottom || '2.4vw'};
  padding-left: ${(props) => props.paddingLeft || '3.733vw'};
  align-items: ${(props) => props.align};
  margin-top: ${(props) => props.marginTop};
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-size: 4.266vw;

  &:disabled {
    cursor: not-allowed;
  }

  @media (min-width: 800px) {
    width: ${(props) => props.mediaWidth || '26.8vw'};
    height: ${(props) => props.mediaHeight || '3vw'};
    margin-bottom: 0.562vw;
    border-radius: 0.625vw;
    padding-left: 0.875vw;
    font-size: 1vw;
    font-weight: 500;
  }

  ::placeholder {
    font-family: 'Libre Franklin', sans-serif;
    color: ${(props) => props.colorPlace || "#CCCCCC"};
    font-size: 4.266vw;
    
    @media (min-width: 800px) {
    font-size: 1vw;
    font-weight: 500;
  }
  }
`;

export const InputTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props) => props.top};
  position: relative;
`;

export const StyleLoading = styled.img`
  width: 3vw;
  height: 3vw;
  position: absolute;
  right: 0.533vw;
  top: 6.933vw;
`;