import styled from 'styled-components'

export const FormStyle = styled.form`
  width: 50vw;
`;

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  @media (min-width: 800px) {
    width: 26.8vw;
    justify-content: center;
    margin-right: 2.5vw;
    margin-left: 20.7vw;
  }
`

export const PaymentText = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 5.866vw;
  font-weight: 500;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Libre Franklin', sans-serif;
  align-self: flex-start;
  padding-left: 15px;
  padding-bottom: 0.8vh;
  @media (min-width: 800px) {
    font-size: 1.375vw;
  }
`