import styled from "styled-components";

export const ContainerInformationLeft = styled.div`
  margin-bottom: 13.8vw;
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  @media (min-width: 800px) {
    display: flex;
    width: 26.8vw;
    flex-direction: column;
    align-items: ${(props) => props.align};
    margin-right: 2.5vw;
    margin-left: 20.7vw;
  }
`