import React, { useEffect } from "react";

import { GeneralContainerPages } from "../../components/GeneralContainerPages/style";
import {
  ContainerSecondStrip,
  IconsHeader,
} from "../../components/Header/style";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import {
  ContainerInformations,
  ContainerTextConcluded,
  ContainerTitleAndIcon,
  IconConcluded,
} from "./style";

import ConcludedIconSVG from "../../assets/images/checkout-icons/checked-green-icon.svg";
import ResolvaIconSVG from "../../assets/images/header-icons/resolva-icon.svg";

export default function PaymentConcluded() {
  useEffect(() => {
    localStorage.removeItem("trackingQueryParams");
  });

  return (
    <GeneralContainerPages>
      <Header display="none" />
      <ContainerInformations>
        <ContainerSecondStrip
          display="flex"
          displayMobile="none"
          alt="logo desktop"
          justifyContent="flex-start"
          marginLeft="0"
        >
          <IconsHeader
            src={ResolvaIconSVG}
            mediaWidth="3.2vw"
            mediaHeight="4.5vw"
          />
          CHECKOUT DO RESOLVA
        </ContainerSecondStrip>
        <ContainerTextConcluded>
          <ContainerTitleAndIcon>
            <IconConcluded src={ConcludedIconSVG} />
            <p>Pagamento foi confirmado!</p>
          </ContainerTitleAndIcon>
          <span>
            Você receberá um email do Resolva Academy para cadastrar sua senha e
            ter acesso ao curso comprado.
          </span>
        </ContainerTextConcluded>
      </ContainerInformations>
      <Footer
        position="fixed"
        bottomPosition="0"
        bottomMobile="0"
        positionMobile="fixed"
      />
    </GeneralContainerPages>
  );
}
