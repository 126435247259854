import { Routes, Route } from "react-router-dom";

import Provider from "./Context/Provider";

import PaymentConcluded from "./pages/PaymentConcluded";
import CheckoutResolva from "./pages/ChekoutResolva";
import ChoosePayment from "./pages/ChoosePayment";
import PaymentType from "./pages/PaymentType";

function App() {
  return (
    <Provider>
      <Routes>
        <Route
          path="/tipo-de-pagamento/:courseId/:courseTitle/:paymentMethod"
          element={<PaymentType />}
        />
        <Route
          path="/escolha-o-pagamento/:courseId/:courseTitle"
          element={<ChoosePayment />}
        />
        <Route
          path="/checkout-resolva/:courseId/:courseTitle"
          element={<CheckoutResolva />}
        />
        <Route
          path="/pagamento-concluido/:courseTitle"
          element={<PaymentConcluded />}
        />
      </Routes>
    </Provider>
  );
}

export default App;
