import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { ContainerTimer, Timer } from "./style";

function Cronometer({ intervalId }) {
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState(4);
  const navigate = useNavigate();
  const { courseId, courseTitle } = useParams();

  useEffect(() => {
    seconds >= 0
      ? setTimeout(() => setSeconds(seconds - 1), 1000)
      : setSeconds(59);
    seconds < 0 && setMinutes((prev) => prev - 1);
    if (!minutes && !seconds) {
      clearInterval(intervalId);
      if (localStorage.getItem("trackingQueryParams")) {
        navigate(
          `/escolha-o-pagamento/${courseId}/${courseTitle}/${localStorage.getItem(
            "trackingQueryParams"
          )}`
        );
      } else {
        navigate(`/escolha-o-pagamento/${courseId}/${courseTitle}`);
      }
    }
  }, [seconds, minutes, courseId, courseTitle, navigate, intervalId]);

  return (
    <ContainerTimer>
      <Timer>{minutes}</Timer>:
      <Timer>{seconds < 10 ? `0${seconds}` : seconds}</Timer>
    </ContainerTimer>
  );
}

export default Cronometer;
