import React from "react";
import { ContainerFirstStripHeader, ContainerSecondStrip, ContainerTextFirstStrip, IconsHeader } from "./style";
import PadlockIconSVG from '../../assets/images/header-icons/padlock-icon.svg';
import ResolvaIconSVG from '../../assets/images/header-icons/resolva-icon.svg';

export default function Header({ display }) {
  return (
    <header>
      <ContainerFirstStripHeader>
        <ContainerTextFirstStrip>
          <IconsHeader src={PadlockIconSVG}></IconsHeader>
          COMPRA 100% SEGURA
        </ContainerTextFirstStrip>
      </ContainerFirstStripHeader>
      <ContainerSecondStrip display={ display }>
        <IconsHeader src={ResolvaIconSVG} mediaWidth='4.1vw' mediaHeight='5.7vw'></IconsHeader>
        CHECKOUT DO RESOLVA
      </ContainerSecondStrip>
    </header>
  )
}