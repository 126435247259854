const checkCardValidity = (card) => {
  if (card.validityDate.length !== 5) return false
  const [ month, year ] = card.validityDate.split('/')
  const currentTime = new Date()
  const currentMonth = currentTime.getMonth() + 1
  const currentYear = currentTime.getFullYear().toString().slice(-2)
  if (Number(month) > 12 || Number(month) < 1) return false
  if (Number(month) <= Number(currentMonth) && Number(year) <= Number(currentYear)) return false
  if(Number(year) < Number(currentYear)) return false
  return true
}

export default checkCardValidity;