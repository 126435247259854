import styled from 'styled-components';

export const ContainerQrCode = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PixButtonCopy = styled.button`
  border: none;
  background-color: #f4f2f2;
  padding: 5.1vw 10.2vw 5.1vw 5.1vw;
  position: relative;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3.5vw;
  color: #c4c3c3;
  border-radius: 2.666vw;
  cursor: pointer;
  margin: 1rem 0 2rem 0;
  width: 89.333vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  @media (min-width: 800px) {
    width: 26.3vw;
    font-size: 0.9vw;
    padding: 20px 0;
    border-radius: 0.625vw;
  }

  span {
    color: black;
    font-weight: bold;
    text-align: center;
    margin-left: 20px;
  }
`

export const CopyIcon = styled.img`
  position: absolute;
  width: 20px;
  right: 9px;
  top: 18px;
`

export const PixButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 89.333vw;
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 3.468vw;

    @media (min-width: 800px) {
    width:26.8vw;
    font-size: 0.813vw;
    } 
  }
  
  @media (min-width: 800px) {
    width:26.8vw;
  }
`;