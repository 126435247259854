import styled from "styled-components";

export const ContainerTextPurchase = styled.div`
  display: flex;
  width: 89.333vw;
  flex: none;
  font-family: 'Libre Franklin', sans-serif;
  font-size: ${(props) => props.fontSize || '5.333vw'};
  font-weight: bold;
  border-bottom-style: ${(props) => props.border || 'solid'};
  border-bottom-width: 2px;
  border-color: #C7C7C7;
  margin-top: ${(props) => props.top || '2.933vw'};
  padding-top: ${(props) => props.padTop};
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  @media (min-width: 800px) {
    width: 21.4vw;
    margin-bottom: ${(props) => props.bottomDesktop || '0.937vw'};
    align-items: flex-start;
    margin-top: 0.125vw;
    align-items: ${(props) => props.alignDesktop};
    font-size: 1.25vw;
    padding-top: ${(props) => props.padTopDesktop};
  }
  h3 {
    font-weight: bold;
    width: 64vw;
    font-size: 5.333vw;
    margin-bottom: 3.733vw;
    @media (min-width: 800px) {
    font-size: 1.25vw;
    width: 15vw;
    margin-bottom: 1.406vw;
  }
  }
  p {
    font-size: 3.733vw;
    font-weight: 500;
    font-family: 'Libre Franklin', sans-serif;
    flex: 'none';
    padding-bottom: ${(props) => props.padBottomMobile || '5.866vw'};
    font-weight: ${(props) => props.fontWeightMobile || '700'};
    margin-left: ${(props) => props.leftMobile};
    width: ${(props) => props.widthMobile || '35vw'};
    margin-top: ${(props) => props.marginTopMobile};
    @media (min-width: 800px) {
      margin-top: 0.3vw;
      font-size: 0.875vw;
      margin-bottom: 0vw;
      margin-left: ${(props) => props.leftDesktop};
      width: ${(props)=> props.widthDesktop || '8vw'};
      padding-bottom: ${(props) => props.padBottomDesktop || '1.25vw'};
      font-weight: ${(props) => props.fontWeightDesktop || '700'};
    }
  }
`;