import styled from "styled-components";

const ContainerIcons = styled.div`
  display: flex;
  width: 89.333vw;
  align-items: center;
  justify-content: space-between;
  display: ${(props) => props.displayMobile || 'flex'};
  padding: 7.2vw 0 2vh 0;
  @media (min-width: 800px) {
    width: 54vw;
    display: ${(props) => props.display};
    margin-left: 20.7vw;
    padding: 0 0 5vw 0;
  }
`;

export default ContainerIcons;