import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import QrCode from "react-qr-code";

import { apiGlobal } from "../../service/api";
import Context from "../../Context/Context";

import { ContainerInformationLeft } from "../ContainerInformationLeft/style";
import { PaymentText } from "../MethodCreditCard/style";
import StyleLoadingGif from "../LoadingGif/style";
import Cronometer from "../Cronometer";

import {
  ContainerEachItemSelectCard,
  ContainerSelectCardOrModify,
  IconsPurchase,
} from "../MethodBankSlip/style";

import {
  ContainerQrCode,
  CopyIcon,
  PixButtonCopy,
  PixButtonContainer,
} from "./style";

import WhitePixIcon from "../../assets/images/checkout-icons/icon-pix-white.png";
import copyIcon from "../../assets/images/checkout-icons/copy-icon2.svg";
import loadingGif from "../../assets/gifs/loading.gif";

function MethodPix() {
  const { formInfo, setFormInfo, courseData } = useContext(Context);

  const [qrCode, setQrCode] = useState({ QR_CODE: "", transactionId: 0 });
  const [intervalId, setIntervalId] = useState();
  const [loading, setLoading] = useState(true);
  const [copy, setCopy] = useState(false);

  const navigate = useNavigate();

  const { courseId, courseTitle } = useParams();

  const pixPrice =
    Number(courseData.currentValue) - Number(courseData.currentValue) * 0.1;

  const getPixPaymentInfo = (dataQrCode) => {
    const interval = setInterval(async () => {
      const pixStatusData = await apiGlobal.checkPixPayment(
        dataQrCode.transactionId
      );

      if (pixStatusData.transactionPaid) {
        setFormInfo({
          state: "",
          city: "",
          neighborhood: "",
          street: "",
          streetNumber: "",
          complement: "",
          zipcode: "",
          customerName: "",
          customerEmail: "",
          customerCpf: "",
          customerBirthday: "",
          customerPhoneNumber: "",
        });

        clearInterval(interval);

        navigate(`/pagamento-concluido/${courseTitle}`);
      }
    }, 5000);

    setIntervalId(interval);
  };

  const getQrCode = async () => {
    try {
      setLoading(true);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);

      const paymentCourseData = {
        ...formInfo,
        amount: pixPrice,
        installments: 1,
        courseId,
        ...courseData,
      };

      const pixData = await apiGlobal.pagarmePixPayment(paymentCourseData);

      setQrCode({ ...pixData });
      setLoading(false);

      if (pixData.transactionId) {
        getPixPaymentInfo(pixData);
      }
    } catch (e) {
      console.log("ERROR PIX");
    }
  };

  let shouldLog = useRef(true);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      getQrCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(qrCode.QR_CODE);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };

  return (
    <ContainerInformationLeft align="center" display="flex" direction="column">
      <PaymentText>Pagamento</PaymentText>
      <ContainerSelectCardOrModify>
        <ContainerEachItemSelectCard>
          <IconsPurchase
            src={WhitePixIcon}
            left="20px"
            leftMobile="4vw"
            width="5vw"
            mediaWidth="1vw"
          />
          <p>PIX</p>
        </ContainerEachItemSelectCard>
        <ContainerEachItemSelectCard onClick={() => navigate(-1)}>
          Modificar
        </ContainerEachItemSelectCard>
      </ContainerSelectCardOrModify>
      {loading ? (
        <StyleLoadingGif src={loadingGif} alt="Carregando" width="200" />
      ) : (
        <>
          {qrCode.QR_CODE !== "" && (
            <PixButtonContainer>
              <p>
                Clique no código abaixo e utilize o Pix Copia e Cola no
                aplicativo que você vai fazer o pagamento:
              </p>
              <PixButtonCopy onClick={handleClickCopy}>
                {copy ? (
                  <span>Copiado!</span>
                ) : (
                  <>
                    {qrCode.QR_CODE.slice(0, 35)}...
                    <CopyIcon src={copyIcon} alt="copiar e colar" />
                  </>
                )}
              </PixButtonCopy>
              <ContainerQrCode>
                <Cronometer intervalId={intervalId} />
                <QrCode value={qrCode.QR_CODE} />
              </ContainerQrCode>
            </PixButtonContainer>
          )}
        </>
      )}
    </ContainerInformationLeft>
  );
}

export default MethodPix;
