import styled from 'styled-components'

export const ButtonExpandPurchaseResume = styled.button`
  display: flex;
  width: 89.333vw;
  font-family: 'Libre Franklin', sans-serif;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  text-indent: 2vw;
  font-size: 3.733vw;
  padding-bottom: 2.1vh;
  flex: none;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const ContainerInsideButton = styled.div`
  display: flex;
  align-items: center;
`;

export const IconTrashContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20vw;
  @media (min-width: 800px) {
    width: 500px;
    display: flex;
  }
`;

export const ContainerTotalPrice = styled.div`
  display: flex;
  font-size: 3.733vw;
  font-weight: 500;
  font-family: 'Libre Franklin', sans-serif;
  @media (min-width: 800px) {
      font-size: 0.875vw;
    }
`;

export const ContainerSideBySide = styled.div`
  width: 89.333vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 800px) {
    width: 26.8vw;
  }
`;

export const ButtonOkCep = styled.button`
  font-family: 'Outfit', sans-serif;
  font-size: 4.266vw;
  font-weight: 700;
  width: 13.333vw;
  height: 13.333vw;
  background-color: #000000;
  color: #ffffff;
  border-radius: 2.666vw;
  margin-top: 10px;
  cursor: pointer;
  @media (min-width: 800px) {
    width: 3vw;
    height: 3vw;
    font-size: 1vw;
    border-radius: 0.625vw;
  }
`;

export const ContainerTerms = styled.div`
  display: flex;
  width: 89.333vw;
  align-items: center;
  justify-content: flex-start;
  padding: 2vh 0 2vh 0;
  @media (min-width: 800px) {
    width: 400px;
  }

  p {
    font-size: 3.468vw;
    font-family: 'Libre Franklin', sans-serif;
    text-indent: 2vw;
      @media (min-width: 800px) {
      text-indent: 10px;
      font-size: 0.813vw;
    }
  }
`;

export const Checkbox = styled.input`
  display: flex;
  width: 6.5vw;
  height: 6.5vw;
  border-style: solid;
  border-color: #000000;
  border-width: 2px;
  accent-color: black;
  cursor: pointer;
  @media (min-width: 800px) {
    width: 1.562vw;
    height: 1.562vw;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || 'center'};
  font-family: 'Libre Franklin', sans-serif;
  font-size: 4.266vw;
  @media (min-width: 800px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100vw;
    font-size: 1vw;
  }
`
export const ResumeText = styled.p`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-size: 3.468vw;
  padding-bottom: 0.8vh;
  padding-left: 15px;
  padding-top: 10px;
  align-self: flex-start;
  @media (min-width: 800px) {
    font-size: 0.813vw;
  }
`;
