import styled from 'styled-components'

export const StateSelect = styled.select`
  width: ${(props) => props.width || '89.333vw'};
  height: 13.333vw;
  border-radius: ${(props) => props.radius || '2.666vw'};
  border: solid 1px;
  border-color: ${(props) => props.borderColor || '#C7C7C7'};
  margin-bottom: ${(props) => props.marginBottom || '2vw'};
  padding-left: ${(props) => props.paddingLeft || '14px'};
  align-items: ${(props) => props.align};
  margin-top: ${(props) => props.marginTop};

  &:disabled {
    background-color: #FAFAFA;
    cursor: not-allowed;
  }

  ::placeholder {
    font-family: 'Libre Franklin', sans-serif;
    color: ${(props) => props.colorPlace || "#CCCCCC"};
  }

  @media (min-width: 800px) {
    width: ${(props) => props.mediaWidth || '26.8vw'};
    height: ${(props) => props.mediaHeight || '3vw'};
    margin-bottom: 1vh;
    border-radius: 0.625vw;
  }
` 