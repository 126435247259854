import PropTypes from "prop-types";
import React, { useState } from "react";
import Context from "./Context";

export default function Provider({ children }) {
  const [formInfo, setFormInfo] = useState({
    state: "",
    city: "",
    neighborhood: "",
    street: "",
    streetNumber: "",
    complement: "",
    zipcode: "",
    customerName: "",
    customerEmail: "",
    customerCpf: "",
    customerBirthday: "",
    customerPhoneNumber: "",
  });
  const [courseData, setCourseData] = useState({
    courseImage: "",
    currentValue: 0,
    hasMaterial: 0,
    originalValue: 0,
    tinyId: null,
    tinySku: null,
    title: "",
    subTrial: 0,
  });

  const contextValue = {
    courseData,
    setCourseData,
    formInfo,
    setFormInfo,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
