import styled from "styled-components";

export const GeneralButton = styled.button`
  display: ${(props) => props.displayMobile || 'flex'};
  flex: none;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  font-size: 4.266vw;
  background-color: ${(props) => props.backColor || '#000000'};
  width: 89.333vw;
  height: 13.333vw;
  border-radius: 2.666vw;
  color: #ffffff;
  justify-content: center;
  margin: 9.066vw 0 9.066vw 0;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #E4E4E4;
    cursor: not-allowed;
  }

  @media (min-width: 800px) {
    width: ${(props) => props.width};
    height: 3vw;
    display: ${(props) => props.display};
    margin: 1.562vw 0 0 0;
    font-size: 1vw;
    border-radius: 0.625vw;
  }
`;