import React, { useContext, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import createPagarmeCardToken from "../../utils/createPagarmeCardToken";
import checkCardValidity from "../../functions/checkCardValidity";
import { apiGlobal } from "../../service/api";
import Context from "../../Context/Context";

import { InputLabel, InputTextContainer } from "../InputText/style";
import StyleLoadingGif from "../LoadingGif/style";
import IconsPurchase from "../IconPurchase/style";
import { ErrorMsg } from "../ErrorMsg/style";
import GeneralInputText from "../InputText";
import Button from "../Button";

import {
  ContainerEachItemSelectCard,
  ContainerSelectCardOrModify,
} from "../MethodBankSlip/style";
import {
  ContainerValidity,
  DropdownInstallments,
  SelectInstallments,
} from "../../pages/PaymentType/style";

import { PaymentForm, PaymentText } from "./style";

import WhiteCreditCardIconSVG from "../../assets/images/checkout-icons/white-credit-card-icon.svg";
import loadingGif from "../../assets/gifs/loading.gif";

function MethodCreditCard() {
  const { formInfo, setFormInfo, courseData } = useContext(Context);

  const [visibility, setVisibility] = useState("hidden");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState("hidden");
  const [card, setCard] = useState({
    holderCardName: "",
    cardNumber: "",
    validityDate: "",
    cvv: "",
    amount: 0,
    installments: 1,
  });

  const { courseId, courseTitle } = useParams();

  const navigate = useNavigate();

  const installments = [2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setCard({
      ...card,
      [name]: value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const cardValidity = checkCardValidity(card);
    if (!cardValidity) {
      setIsDisabled(false);
      setVisibility("visible");
      setTimeout(() => {
        setVisibility("hidden");
      }, 5000);
      return;
    }

    const cardToken = await createPagarmeCardToken({
      cardNumber: card.cardNumber.replaceAll(" ", ""),
      cardHolderName: card.holderCardName,
      expMonth: card.validityDate.split("/")[0],
      expYear: card.validityDate.split("/")[1],
      cardCvv: card.cvv,
    });

    if (cardToken === "Error") {
      console.log("ERROR CARD TOKEN");
      setIsDisabled(false);
      setVisibility("visible");
      setTimeout(() => {
        setVisibility("hidden");
      }, 5000);
      return;
    }

    const paymentCourseData = {
      ...formInfo,
      cardToken,
      amount: courseData.currentValue,
      installments: card.installments,
      courseId,
      ...courseData,
    };

    try {
      setLoading("visible");

      await apiGlobal.pagarmeCreditCardPayment(paymentCourseData);

      setFormInfo({
        state: "",
        city: "",
        neighborhood: "",
        street: "",
        streetNumber: "",
        complement: "",
        zipcode: "",
        customerName: "",
        customerEmail: "",
        customerCpf: "",
        customerBirthday: "",
        customerPhoneNumber: "",
      });
      navigate(`/pagamento-concluido/${courseTitle}`);
    } catch (e) {
      console.log("ERROR PAGAMENTO CARTÃO");
      setLoading("hidden");
      setIsDisabled(false);
      setVisibility("visible");
      setTimeout(() => {
        setVisibility("hidden");
      }, 5000);
    }
  };

  return (
    <PaymentForm onSubmit={(e) => handleSubmitForm(e)}>
      <PaymentText>Pagamento</PaymentText>
      <ContainerSelectCardOrModify>
        <ContainerEachItemSelectCard>
          <IconsPurchase
            src={WhiteCreditCardIconSVG}
            left="20px"
            leftMobile="4vw"
          ></IconsPurchase>
          <p>Cartão de Crédito</p>
        </ContainerEachItemSelectCard>
        <ContainerEachItemSelectCard onClick={() => navigate(-1)}>
          Modificar
        </ContainerEachItemSelectCard>
      </ContainerSelectCardOrModify>
      <DropdownInstallments>
        <InputTextContainer>
          <InputLabel>Quantidade de parcelas</InputLabel>
          <SelectInstallments
            name="installments"
            onChange={handleInputChange}
            value={card.installments}
          >
            <option value={1}>
              1x de{" "}
              {Number(courseData.currentValue)?.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </option>
            {installments.map((installment, index) => (
              <option value={installment} key={index}>
                {`${installment}x de R$${(
                  courseData.currentValue / installment
                ).toFixed(2)}`}
              </option>
            ))}
          </SelectInstallments>
        </InputTextContainer>
      </DropdownInstallments>
      <GeneralInputText
        placeholder="0000 0000 0000 0000"
        text="Número do Cartão"
        name="cardNumber"
        value={card.cardNumber}
        pattern=".{19,}"
        errorMsg="Digite um número de cartão válido"
        handleInputChange={handleInputChange}
      ></GeneralInputText>
      <GeneralInputText
        placeholder="Nome e Sobrenome"
        text="Nome no Cartão"
        handleInputChange={handleInputChange}
        value={card.holderCardName}
        name="holderCardName"
        errorMsg="Preencha o seu nome"
        maxLenght="60"
      ></GeneralInputText>
      <ContainerValidity mediaWidth="13.1vw">
        <GeneralInputText
          placeholder="mês/ano"
          text="Validade"
          width="54vw"
          mediaWidth="13.1vw"
          handleInputChange={handleInputChange}
          value={card.validityDate}
          name="validityDate"
          errorMsg="Preencha corretamente a validade do seu cartão(mês/ano)"
          pattern=".{5,}"
        />
        <GeneralInputText
          placeholder="000"
          text="CVV"
          width="29.5vw"
          mediaWidth="13.1vw"
          handleInputChange={handleInputChange}
          value={card.cvv}
          name="cvv"
          errorMsg="Preencha corretamente o CVV"
          maxLenght="4"
          minLenght="3"
        />
      </ContainerValidity>
      <ErrorMsg visibility={visibility}>
        Verifique os dados do cartão e tente novamente
      </ErrorMsg>
      <Button
        text="CONTINUAR"
        backColor="#E5E5E5"
        alt="Botão MOBILE"
        type="submit"
        width="26.8vw"
        disabled={isDisabled}
      />
      <StyleLoadingGif
        src={loadingGif}
        visibility={loading}
        alt="Carrregando"
        width="100px"
      />
    </PaymentForm>
  );
}

export default MethodCreditCard;
