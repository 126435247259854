import styled from "styled-components";

export const ContainerTimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
`

export const Timer = styled.span`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-size: 5.12vw;
  @media (min-width: 800px) {
    font-size: 1.2vw;
  }
`