import styled from 'styled-components';

export const ContainerPurchaseResume = styled.div`
  display: flex;
  flex-direction: column;
  width: 89.333vw;
  overflow: hidden;
  @media (min-width: 800px) {
    order: 2;
    width: 26.875vw;
    padding-top: 5vh;
    flex: none;
    background-color: #FAFAFA;
    padding-left: 2.5vw;
    min-height: 59vh;
  }
`;