import styled from "styled-components";

export const IconsPurchase = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: ${(props) => props.leftMobile};
  margin-bottom: ${(props) => props.bottomMobile};
  @media (min-width: 800px) {
    width: ${(props) => props.mediaWidth};
    height: ${(props) => props.mediaHeight};
    margin-left: ${(props) => props.left};
    margin-bottom: ${(props) => props.bottomDesktop};
  }
`;

export default IconsPurchase;
