import React from "react";
import { InputLabel, ContainerLabelAndInputText, InputTextContainer, InputText, StyleLoading } from "./style";
import loadingGif from '../../assets/gifs/loading.gif'

export default function GeneralInputText({
  text,
  placeholder,
  width,
  mediaWidth,
  mediaHeight,
  disable,
  name,
  handleInputChange,
  handleCepValidation,
  value,
  type,
  min,
  max,
  pattern,
  errorMsg,
  maxLenght,
  minLenght,
  loadingCep
}) {

  const inputMask = (e) => {
    let value = e.currentTarget.value;

    switch(text) {
      case 'Nome Completo':
        value = value.replace(/[0-9'"!@#$%¨&*()_=+\b-]/g, "");
        e.currentTarget.value = value;
        break;
      case 'Profissão':
        value = value.replace(/[0-9'"!@#$%¨&*()_=+\b-]/g, "");
        e.currentTarget.value = value;
        break;
      case 'CEP':
        e.currentTarget.maxLength = 9;
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{5})(\d)/, "$1-$2");
        e.currentTarget.value = value;
        break;
      case 'CPF':
        e.currentTarget.maxLength = 14;
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        e.currentTarget.value = value;
        break;
      case 'Whatsapp':
        e.currentTarget.maxLength = 15;
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "($1) $2");
        value = value.replace(/(\d{5})(\d{1,4})$/, "$1-$2");
        e.currentTarget.value = value;
        break;
      case 'Número do Cartão':
        e.currentTarget.maxLength = 19;
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{4})(\d)/, "$1 $2");
        value = value.replace(/(\d{4})(\d)/, "$1 $2");
        value = value.replace(/(\d{4})(\d{1,4})$/, "$1 $2");
        e.currentTarget.value = value;
        break;
      case 'Validade':
        e.currentTarget.maxLength = 5;
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d{1,3})$/, "$1/$2");
        e.currentTarget.value = value;
        break;
      case 'Data de Nascimento':
        e.currentTarget.maxLength = 10;
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "$1/$2");
        value = value.replace(/(\d{2})(\d{1,4})$/, "$1/$2");
        e.currentTarget.value = value;
        break;
      default:
        console.log('default')
    }
  }
  return (
    <ContainerLabelAndInputText width={mediaWidth}>
      <InputTextContainer>
        <InputLabel>{ text }</InputLabel>
        <InputText
          placeholder={placeholder}
          width={width}
          mediaWidth={mediaWidth}
          mediaHeight={mediaHeight}
          disabled={disable}
          name={name}
          onChange={handleInputChange}
          onBlur={handleCepValidation}
          value={value}
          onKeyUp={inputMask}
          required
          type={type}
          pattern={pattern}
          onInvalid={e => e.target.setCustomValidity(errorMsg)}
          onInput={e => e.target.setCustomValidity('')}
          min={min}
          max={max}
          maxLength={maxLenght}
          minLength={minLenght}
        />
          {
            loadingCep && <StyleLoading src={loadingGif} alt="Carregando" />
          }
      </InputTextContainer>
    </ContainerLabelAndInputText>
  )
}