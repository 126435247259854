import React from "react";
import { ButtonPayment, IconPaymentMethod } from "./style";
import { useNavigate } from 'react-router-dom';

export default function ButtonChoosePaymentMethod({ text, src, navigateUrl, widthIcon, heightIcon, widthIconMedia, heightIconMedia }) {
  const navigate = useNavigate()

  return (
    <ButtonPayment onClick={() => navigate(navigateUrl)}>
      <IconPaymentMethod
        src={ src }
        widthIcon={ widthIcon }
        heightIcon={ heightIcon }
        widthIconMedia={ widthIconMedia }
        heightIconMedia={ heightIconMedia }
      >
      </IconPaymentMethod>
      { text }
    </ButtonPayment>
  )
}