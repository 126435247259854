import axios from "axios";

async function pagarmeCreditCardPayment(paymentCourseData) {
  const response = await axios.post(
    `${process.env.REACT_APP_CHECKOUT_BASE_URL}checkout/course-academy/credit-card-order`,
    paymentCourseData
  );

  return response.data;
}

async function pagarmePixPayment(paymentCourseData) {
  const response = await axios.post(
    `${process.env.REACT_APP_CHECKOUT_BASE_URL}checkout/course-academy/pix-order`,
    paymentCourseData
  );

  return response.data;
}

async function checkPixPayment(transactionId) {
  const response = await axios.get(
    `${process.env.REACT_APP_CHECKOUT_BASE_URL}checkout/course-academy/payment-status/${transactionId}`
  );

  return response.data;
}

async function getCourseAcademyData(courseId) {
  const response = await axios.get(
    `${process.env.REACT_APP_ADMIN_BASE_URL}course-academy/${courseId}`
  );

  return response.data;
}

export const apiGlobal = {
  pagarmeCreditCardPayment,
  pagarmePixPayment,
  checkPixPayment,
  getCourseAcademyData,
};
